<template>

  <div v-if="isFilterable">
    <el-row>
      <el-col :span="4" class="mr-1">
        <h6>Datum von</h6>
      </el-col>
      <el-col :span="4" class="mr-1">
        <h6>Datum bis</h6>
      </el-col>
      <el-col :span="4" class="mr-1">
        <h6>Studie</h6>
      </el-col>
      <el-col :span="9" class="mr-1">
        <h6>Proband/in</h6>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4" class="mr-1">
        <el-date-picker
          v-model="filter.date_from"
          class="w-100"
          type="date"
          format="DD.MM.YYYY"
          value-format="YYYY-MM-DD"
          placeholder="Datum auswählen">
        </el-date-picker>
      </el-col>
      <el-col :span="4" class="mr-1">
        <el-date-picker
          v-model="filter.date_to"
          class="w-100"
          type="date"
          format="DD.MM.YYYY"
          value-format="YYYY-MM-DD"
          placeholder="Datum auswählen">
        </el-date-picker>
      </el-col>
      <el-col :span="4" class="mr-1">
        <el-select v-model="filter.survey_id">
          <el-option label="Keine Auswahl" :value="null"></el-option>
          <el-option
            v-for="item in surveys"
            :key="item.id"
            :label="item.title_short"
            :value="item.id"
          />
        </el-select>
      </el-col>
      <el-col :span="8" class="mr-1">
        <el-input v-model="filter.search" placeholder="Name, GebDatum, Studiennummer, Praxis ID"></el-input>
      </el-col>
      <el-col :span="3" class="ml-1">

        <el-dropdown>
          <el-button type="primary" >
            <i class="el-icon-printer"></i> <i class="el-icon-arrow-down"></i>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="openPdfWindow('full')">Mit Patientendaten</el-dropdown-item>
              <el-dropdown-item @click="openPdfWindow('anonym')">Ohne Patientendaten</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

      </el-col>
    </el-row>
  </div>

  <div v-if="tableData">

    <el-table
        ref="patientsTable"
        :data="tableData"
        :default-sort = "{prop: 'date', order: 'ascending'}"
        style="width: 100%"
        highlight-current-row
        @row-click="onRowClick"
        @row-contextmenu="onRowRightClick"
        @expand-change="onRowExpand"
        stripe>
      <el-table-column v-if="rowExpandable" type="expand">
      </el-table-column>
      <el-table-column
        label="ID"
        v-if="tableColomns.id"
        width="100"
        prop="id">
      </el-table-column>
      <el-table-column
        v-if="tableColomns.name"
        label="Proband/in"
        width="300">
        <template #default="scope">
          {{scope.row.name}}, {{scope.row.firstname}}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableColomns.birthdate"
        label="Geburtsdatum"
        width="180">
        <template #default="scope">
          {{ $filters.germanDate(scope.row.birthday) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableColomns.surveys"
        label="Studien">
        <template #default="scope">
          <div v-if="scope.row.surveys">
            <span v-for="patientsurvey in scope.row.surveys" :key="patientsurvey.id">
              <span v-if="patientsurvey.survey">{{patientsurvey.survey.title_short}}</span> {{ $filters.germanDate(patientsurvey.date_agreement) }} <span v-if="patientsurvey.patient_survey_id">#{{patientsurvey.patient_survey_id}}</span>
            </span>
          </div>
        </template>
      </el-table-column>


      <el-table-column
          v-if="rowDeleteable"
          width="70">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PatientsTable",
  props: {
      data: Array,
      columns: Array,
      expandable: Boolean,
      clickable: Boolean,
      deleteable: Boolean,
      filterable: Boolean,
      clickhandler: Function
  },
  emits: [
    'onRowDelete'
  ],
  components: {

  },
  data() {
    return {
      rowExpandable: this.expandable || false,
      rowClickable: this.clickable || false,
      rowDeleteable: this.deleteable || false,
      isFilterable: (this.filterable !== undefined && (this.filterable === true || this.filterable === false)) ? this.filterable : true,
      tableData: this.data,
      filter: {
        date_from: null,
        date_to: null,
        survey_id: null,
        search: ''
      },
      tableColomns: {
        id: this.columns ? this.columns.indexOf('id') > -1 : true,
        name: this.columns ? this.columns.indexOf('name') > -1 : true,
        birthdate: this.columns ? this.columns.indexOf('birthdate') > -1 : true,
        surveys: this.columns ? this.columns.indexOf('surveys') > -1 : true,
      }
    }
  },
  mounted: function () {
    this.getSurveys()
  },
  computed: {
      ...mapGetters('surveys', ['surveys']),
  },
  watch: {
    data: function (val) {
      this.tableData = val
    },
    'filter.date_from': function() {
      this.onFilter()
    },
    'filter.date_to': function() {
      this.onFilter()
    },
    'filter.survey_id': function() {
      this.onFilter()
    },
    'filter.search': function() {
        this.onFilter()
    }
  },
  methods: {
    ...mapActions('surveys', ['getSurveys']),
    onRowDeleteConfirmed(row) {
      this.$emit('onRowDelete', row)
    },
    gotoEditDiagnosisView(row) {
      this.$router.push({name: 'edit_diagnosis', params: {id: row.patient_id, patient_diagnosis_id: row.id}})
    },
    onRowEditClick(row) {
      this.gotoEditDiagnosisView(row)
    },
    onRowClick(row) {
      if(this.rowClickable) {
        this.$emit('onRowClick', row)
        if(row.patient_id) this.gotoEditDiagnosisView(row)
      }
    },
    onRowRightClick(row) {
      if(this.rowExpandable) {
        this.$refs.diagnosisTable.setCurrentRow(row)
        this.$refs.diagnosisTable.toggleRowExpansion(row)
      }
    },
    onRowExpand(row) {
      this.$refs.diagnosisTable.setCurrentRow(row)
    },
    getFilterQuery() {
      let query = "?"
      query += this.filter.date_from ? "&date_from="+this.filter.date_from : ''
      query += this.filter.date_to ? "&date_to="+this.filter.date_to : ''
      query += this.filter.survey_id ? "&survey_id="+this.filter.survey_id : ''
      query += this.filter.search ? "&search="+this.filter.search : ''
      return query
    },
    async onFilter() {

      const query = this.getFilterQuery()
      if(query != '') {
        this.tableData = (await axios.get('/patients/' + query)).data
      }

    },
    async openPdfWindow(mode) {

      const query = this.getFilterQuery()

      if(window.gui) {
        // @TODO should be signed url for one time access
        window.gui.send('print-file', { url: axios.defaults.baseURL + '/export/patients/'+query+'&mode=' + mode, token: localStorage.getItem("dacy-token") })
      }
      else {
        const pdfData = (await axios.get('/export/patients/' + query + '&mode=' + mode, {
          responseType: 'blob'
        })).data
        const url = window.URL.createObjectURL(new Blob([pdfData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'dacy_patients_list.pdf');
        document.body.appendChild(link);
        link.click();
      }

    }
}
}
</script>
<style scoped>
</style>
